import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { CategoryPropType } from 'consts';
import { useCategories, useSegment } from 'contexts';
import { calcCategoryColor, getCategoryUrl } from 'utils';

import './CategoryLink.scss';

const CategoryLink = ({ className, category, showArrow }) => {
  const { activeSegment } = useSegment();
  const { categories: allCategories } = useCategories();
  const categoryColor = calcCategoryColor(category, allCategories);
  const segment = (category.segments || activeSegment).split(',')[0];

  if (!category || !category.id) {
    return '';
  }

  return (
    <NavLink to={getCategoryUrl({ slug: category.slug, segment })} style={{ color: categoryColor }} className={clsx('category-link', className)}>
      <span>{category.name}</span>
      {showArrow && <span className="material-icons">call_made</span>}
    </NavLink>
  );
};

CategoryLink.propTypes = {
  className: PropTypes.string,
  category: CategoryPropType.isRequired,
  showArrow: PropTypes.bool,
};

CategoryLink.defaultProps = {
  className: '',
  showArrow: false,
};

export default CategoryLink;
