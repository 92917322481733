import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';

import { calcCategoryColor, findCategoryBySlug } from 'utils';
import { useCategories, usePostsByCategorySlug } from 'contexts';
import { PostRedirect } from 'view/base';
import { /* CategoryBreadcrumb, */ Pagination, PostTile } from 'view/components';
import { MainView } from 'view/modules';

import './CategoryPage.scss';
import './CategoryPage.responsive.scss';

const CategoryPage = ({ className, match, showCategoryName, imgSrc }) => {
  const { path: basePath, params: { category: categorySlug, page } } = match;
  const { categories } = useCategories();
  const category = categories && findCategoryBySlug(categories, categorySlug);

  const { posts, pages } = usePostsByCategorySlug(categorySlug, page);

  if (categories && !category) {
    // ... and return 404 or redirec to root page
    return <Redirect to="/" />;
  }

  if (posts && pages === 1 && posts.length === 1) {
    return <PostRedirect post={posts[0]} />;
  }

  const categoryColor = calcCategoryColor(category, categories);

  return (
    <MainView loaded={!!posts} imgSrc={imgSrc} fullWidth>
      <div className={clsx(className, 'category-page')}>
        {category && (
          <React.Fragment>
            {/* <CategoryBreadcrumb categories={categories} category={category} /> */}
            {showCategoryName && <div className="head" style={{ color: categoryColor }}>{category.name}</div>}
            <div className="posts">
              {(posts || []).map((post, idx) => (
                <PostTile className={clsx({ even: idx % 2 })} key={post.id} defaultCategoryColor={categoryColor} {...post} />
              ))}
            </div>
            {!!pages && <Pagination basePath={basePath} pathParams={{ category: categorySlug }} pages={pages} page={page} />}
          </React.Fragment>
        )}
      </div>
    </MainView>
  );
};

CategoryPage.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
  showCategoryName: PropTypes.bool,
  imgSrc: PropTypes.string,
};

CategoryPage.defaultProps = {
  className: '',
  showCategoryName: true,
  imgSrc: null,
};

export default CategoryPage;
