import { mapValues, merge } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { servicesConfig, BASE_URL } from 'consts';
import { provider } from 'utils';
import { useAxiosCache as axiosCacheGlobal } from 'utils/axios-cache';

const ServiceProvider = ({ axiosCache, headers, preview, children }) => {
  const services = useMemo(() => {
    const serviceConfigMapper = ({ url, noCache, ...cfg } = {}) => merge({}, cfg, {
      url: BASE_URL + url,
      api: noCache ? axios : axiosCache,
      headers,
    });

    // add 'preview' into params (used by only services which has 'preview' defined)
    const serviceMapper = (fn) => (params = {}, cfg = {}) => (
      fn(preview ? { ...params, preview } : params, cfg)
    );

    return mapValues(provider(mapValues(servicesConfig, serviceConfigMapper)), serviceMapper);
  }, [preview, axiosCache, headers]);

  return (
    <ServiceProvider.Context.Provider value={services}>
      {children}
    </ServiceProvider.Context.Provider>
  );
};

ServiceProvider.Context = createContext([false, () => {}]);

ServiceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  axiosCache: PropTypes.func,
  preview: PropTypes.bool,
  headers: PropTypes.shape({}),
};

ServiceProvider.defaultProps = {
  preview: false,
  axiosCache: axiosCacheGlobal,
  headers: {},
};

export const useServiceId = (serviceId) => {
  const services = useContext(ServiceProvider.Context);
  if (!services) {
    throw new Error('Error calling `useService`: a parent ServiceProvider component must be present!');
  }

  const service = services[serviceId];
  if (!service) {
    throw new Error(`Service method '${serviceId}' not found!`);
  }

  return service;
};

export const useCachedService = (serviceId, params, config) => useServiceId(serviceId)(params, config);

export const useService = (serviceId, options = { autoStart: false, config: {}, params: {} }) => {
  const [state, setState] = useState({ loading: false });

  const service = useServiceId(serviceId);

  const refetch = useCallback(async (params, config) => {
    try {
      const canceller = axios.CancelToken.source();
      setState({ ...state, loading: true, cancel: canceller.cancel });

      const response = await service(params, { ...config, cancelToken: canceller.token });

      setState({ ...state, loading: false, error: null, response, data: response && response.data });

      return { response };
    } catch (error) {
      const { response, response: { data } = {} } = error;
      setState({ ...state, loading: false, error, response, data, cancelled: axios.isCancel(error) });

      return { error, response: error.response };
    }
  }, [state, service]);

  useEffect(() => {
    const { autoStart, params, config } = options;
    if (!autoStart || state.loading) {
      return undefined;
    }
    refetch(params, config);

    return () => {
      if (state.cancel) {
        state.cancel();
      }
    };
  }, [state, refetch, options]);

  return [state, refetch, options];
};

export default ServiceProvider;
