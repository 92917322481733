import { each, reduce } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import qs from 'qs';
import cookie from 'cookie';
import { FacebookProvider } from 'react-facebook';

import { PostContentMode, FACEBOOK_APP_ID, IS_BROWSER } from 'consts';
import { decodeHtml, fromJson } from 'utils';

import SegmentProvider from './SegmentProvider';
import ServiceProvider from './ServiceProvider';

const MAIN_MODULE_PARSER_REGEX = /\$\{(.+?)(?::(.+?))?\}/is;
const MAIN_MODULE_PARSER_REGEX_GLOBAL = new RegExp(MAIN_MODULE_PARSER_REGEX.source, 'isg');
const SUB_MODULE_PARSER_REGEX = /\$\[(.+?)(?::(.+?))?\]/is;
const SUB_MODULE_PARSER_REGEX_GLOBAL = new RegExp(SUB_MODULE_PARSER_REGEX.source, 'isg');

const encodeSubModules = (content) => {
  const subModules = [];
  const encoded = content.replace(/\$\[(.+?)\]/gi, (moduleStr) => {
    subModules.push(moduleStr);
    return `$[SUBMODULE:${subModules.length - 1}]`;
  });
  return { subModules, encoded };
};

const decodeSubModules = (content, subModules) => (
  content.replace(/\$\[SUBMODULE:(\d+)\]/, (moduleStr, moduleIdx) => subModules[moduleIdx])
);

const stripParagraph = (contentMode, key, value) => {
  if (contentMode !== PostContentMode.WYSIWYG) {
    return { key, value };
  }

  const strip = key[0] === '%';
  return {
    key: strip ? key.substr(1) : key,
    value: strip ? value.replace(/<\/p><p>/ig, '\n') : value,
  };
};

const createDynamicModules = (content, { main, modulesMap = {} }) => {
  if (!content) {
    return { html: content, modules: [] };
  }
  const parserRegExp = main ? MAIN_MODULE_PARSER_REGEX : SUB_MODULE_PARSER_REGEX;
  const parserRegExpGlobal = main ? MAIN_MODULE_PARSER_REGEX_GLOBAL : SUB_MODULE_PARSER_REGEX_GLOBAL;
  const modules = [];
  const html = content.replace(parserRegExpGlobal, (matched) => {
    const [, moduleName, moduleParams] = matched.match(parserRegExp);
    const Component = modulesMap[moduleName.toUpperCase()];
    if (!Component) {
      return '';
    }
    const wrapperId = `dyn-${moduleName}-${modules.length}-wrapper`;
    modules.push({ wrapperId, moduleName, moduleParams, Component });
    return `<div id="${wrapperId}"></div>`;
  });
  return { html, modules };
};

const useDynamicModules = (content, { main = true, externalProps, modulesMap, contentMode } = {}) => {
  const ref = useRef();
  const { html, modules } = useMemo(() => {
    const result = createDynamicModules(content, { main, modulesMap });
    return result;
  }, [content, main, modulesMap]);

  useEffect(() => {
    if (!IS_BROWSER || !modules || !ref.current) {
      return undefined;
    }
    const baseRef = ref.current;
    each(modules, ({ wrapperId, Component, moduleParams }) => {
      const wrapper = baseRef.querySelector(`#${wrapperId}`);
      if (wrapper) {
        const { encoded: paramsStrEcnoded, subModules } = encodeSubModules(contentMode === PostContentMode.WYSIWYG ? decodeHtml(moduleParams) : moduleParams);
        const moduleProps = qs.parse(paramsStrEcnoded) || {};
        const props = {
          ...reduce(moduleProps, (acc, valueEncoded, keyEncoded) => {
            const { key, value } = stripParagraph(contentMode, keyEncoded, decodeSubModules(valueEncoded, subModules));
            return {
              ...acc,
              [key]: fromJson(value, value),
            };
          }, {}), ...externalProps,
        };

        const preview = !!qs.parse(window.location.search).preview;
        const cookies = cookie.parse(document.cookie);
        const component = (
          <HelmetProvider>
            <BrowserRouter>
              <ServiceProvider preview={preview}>
                <SegmentProvider cookies={cookies}>
                  <FacebookProvider appId={FACEBOOK_APP_ID}>
                    <Component {...props} />
                  </FacebookProvider>
                </SegmentProvider>
              </ServiceProvider>
            </BrowserRouter>
          </HelmetProvider>
        );
        ReactDOM.render(component, wrapper);
      }
    });

    return () => {
      each(modules, ({ wrapperId }) => {
        const wrapper = baseRef.querySelector(`#${wrapperId}`);
        if (wrapper) {
          ReactDOM.unmountComponentAtNode(wrapper);
          wrapper.outerHTML = `<div id="${wrapperId}"></div>`;
        }
      });
    };
  }, [modules, contentMode, externalProps]);

  return { html, modules, ref };
};

export default useDynamicModules;
