import PropTypes from 'prop-types';

export const BannerPropType = PropTypes.shape({
  title: PropTypes.string,
  imageMediaId: PropTypes.number,
  position: PropTypes.string,
  target: PropTypes.string,
  priority: PropTypes.number,
});

export const CategoryPropType = PropTypes.shape({});

export const ImagePropType = PropTypes.shape({});

export const TagPropType = PropTypes.shape({});

export const PostPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  lead: PropTypes.string,
  image: ImagePropType,
  imageStyles: PropTypes.string,
  approvedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  categories: PropTypes.arrayOf(CategoryPropType.isRequired),
});

export const ChildrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);
