import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { PostPropType } from 'consts';
import { useCategories } from 'contexts';
import { getCroppingImageStyles, getDisplayDate, getImageUrl, calcCategoryColor } from 'utils';
import { CategoryLink, PostLink } from 'view/base';

import './Post.scss';
import './Post.responsive.scss';

const Post = ({ className, post, imageSize, defaultCategoryColor, showCategory, crop }) => {
  const { categories } = useCategories();
  const {
    id, title, lead, image, imageMediaId, imageStyles, approvedAt, updatedAt, createdAt, slug, redirectUrl, segment,
    categories: [postCategory] = [], category,
  } = post;

  const realCategory = postCategory || category;

  const displayDate = getDisplayDate(approvedAt || updatedAt || createdAt);
  const renderDate = !!displayDate;

  const renderCategory = showCategory && realCategory && categories;
  const categoryColor = (realCategory && categories && calcCategoryColor(realCategory, categories)) || defaultCategoryColor;

  const imageUrl = image || imageMediaId ? getImageUrl(image || { id: imageMediaId }, getCroppingImageStyles(imageStyles, crop), imageSize) : undefined;

  return (
    <article key={id} className={clsx('post-item', className, { 'no-image': !imageUrl })} style={{ borderBottomColor: categoryColor }}>
      <div className="post-inner">
        {imageUrl && (
          <PostLink className="image-link" slug={slug} segment={segment} redirectUrl={redirectUrl}>
            <img className="image" src={imageUrl} alt={title} />
          </PostLink>
        )}
        <div className="details">
          <h1>
            <PostLink className="title post-link" style={{ color: categoryColor }} slug={slug} segment={segment} redirectUrl={redirectUrl}>{title}</PostLink>
          </h1>
          {(renderCategory || renderDate) && (
            <h3 className="created-at-and-category">
              {renderDate && <span className="created-at">{displayDate}{renderCategory && ', '}</span>}
              {renderCategory && <CategoryLink className="category" category={{ ...realCategory, segments: segment }} showArrow />}
            </h3>
          )}
          {!!lead && (
            <h2 className="lead">
              {lead}
              <div className="read-more">
                <PostLink className="post-link" slug={slug} segment={segment} redirectUrl={redirectUrl} style={{ color: categoryColor }}>
                  &raquo;
                </PostLink>
              </div>
            </h2>
          )}
          <div className="read-more">
            <PostLink className="post-link" slug={slug} segment={segment} redirectUrl={redirectUrl} style={{ color: categoryColor }}>
              Tovább olvasom &raquo;
            </PostLink>
          </div>
        </div>
      </div>
    </article>
  );
};

Post.propTypes = {
  className: PropTypes.string,
  defaultCategoryColor: PropTypes.string,
  imageSize: PropTypes.string,
  post: PostPropType.isRequired,
  showCategory: PropTypes.bool,
  crop: PropTypes.string,
};

Post.defaultProps = {
  className: '',
  defaultCategoryColor: undefined,
  imageSize: undefined,
  showCategory: false,
  crop: 'wideCrop',
};

export default Post;
