import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Login } from 'react-facebook';

import { Button } from 'view/base';

import usePoll from './usePoll';

import './VoteButton.scss';

const VoteButton = ({ className, post }) => {
  const [disabled, setDisabled] = useState(false);
  const { available, onLoginComplete, onLoginError, reset, message } = usePoll(post && post.poll);

  useEffect(() => {
    setDisabled(!!message);
  }, [message]);

  if (!available) {
    return '';
  }

  return (
    <div className={clsx('vote-button', className)}>
      <Login scope="email" onCompleted={onLoginComplete} onError={onLoginError} eventKey={post.id}>
        {({ handleClick/* , loading , error */ }) => (
          <Button
            className="green text"
            disabled={disabled}
            onClick={(...args) => {
              setDisabled(true);
              return handleClick(...args);
            }}
          >Szavazok!
          </Button>
        )}
      </Login>
      {message && (
        <div className="vote-message">
          <div className="vote-message-dialog">
            <div className="msg-icon material-icons">{message.icon}</div>
            <div className="msg-title">{message.title}</div>
            <div className="msg-content">{message.content}</div>
            {message.button && <Button className="msg-button green text" onClick={reset}>{message.button}</Button>}
          </div>
        </div>
      )}
    </div>
  );
};

VoteButton.propTypes = {
  className: PropTypes.string,
  post: PropTypes.shape({
    id: PropTypes.number,
    poll: PropTypes.shape({}),
  }).isRequired,
};

VoteButton.defaultProps = {
  className: '',
};

export default VoteButton;
