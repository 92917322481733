/* eslint-disable react/button-has-type */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Button.scss';

const IconButton = ({ className, outlined, icon, alt, children, ...buttonProps }) => (
  <button className={clsx('button', className, { outlined })} {...buttonProps}>
    {icon && <img src={icon} alt={alt} />}
    {children}
  </button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  type: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  alt: PropTypes.string,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
};

IconButton.defaultProps = {
  className: '',
  style: {},
  type: 'button',
  icon: undefined,
  alt: undefined,
  onClick: undefined,
  children: '',
  outlined: false,
};

export default IconButton;
