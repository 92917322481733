const Pages = {
  AboutUs: {
    title: 'Rólunk',
    mobileOnly: true,
    sitemap: {
      slug: 'rolunk',
    },
    route: {
      path: '/rolunk',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'rolunk' } },
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  Partners: {
    title: 'Partnereink',
    sitemap: {
      slug: 'partnereink',
    },
    route: {
      path: '/partnereink',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'partnereink' } },
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  Programs: {
    title: 'Programjaink',
    mobileOnly: true,
    sitemap: {
      disabled: true,
      slug: 'programjaink',
    },
    route: {
      path: '/programjaink/:page?',
    },
    component: {
      type: 'CategoryPage',
      props: {
        match: { params: { category: 'programjaink' } },
      },
    },
  },

  Research: {
    title: 'Kutatásaink',
    mobileOnly: true,
    sitemap: {
      disabled: true,
      slug: 'kutatasaink',
    },
    route: {
      path: '/kutatasaink/:page?',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'kutatasaink' } },
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  Studies: {
    title: 'Tanulmányaink',
    mobileOnly: true,
    sitemap: {
      disabled: true,
      slug: 'tanulmanyaink',
    },
    route: {
      path: '/tanulmanyaink/:page?',
    },
    component: {
      type: 'CategoryPage',
      props: {
        match: { params: { category: 'tanulmanyaink' } },
      },
    },
  },

  Press: {
    title: 'Sajtómegjelenések',
    mobileOnly: true,
    sitemap: {
      disabled: true,
      slug: 'sajtomegjelenesek',
    },
    route: {
      path: '/sajtomegjelenesek/:page?',
    },
    component: {
      type: 'CategoryPage',
      props: {
        match: { params: { category: 'sajtomegjelenesek' } },
      },
    },
  },

  Impressum: {
    title: 'Impresszum',
    sitemap: {
      slug: 'impresszum',
    },
    route: {
      path: '/impresszum',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'impresszum' } },
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  Contact: {
    title: 'Kapcsolat',
    mobileOnly: true,
    sitemap: {
      slug: 'kapcsolat',
    },
    route: {
      path: '/kapcsolat',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'kapcsolat' } },
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  PrivacyInfo: {
    title: 'Adatvédelmi tájékoztató',
    sitemap: {
      slug: 'adatvedelmi-tajekoztato',
    },
    route: {
      path: '/adatvedelmi-tajekoztato',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'adatvedelmi-tajekoztato' } },
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  Category: {
    title: 'Kategóriák',
    route: {
      path: '/kategoria/:category/:page?',
      exact: true,
    },
    component: {
      type: 'CategoryPage',
    },
  },

  Tag: {
    title: 'Cimkék',
    route: {
      path: '/cimke/:tag/:page?',
      exact: true,
    },
    component: {
      type: 'TagPage',
    },
  },

  Video: {
    title: 'Videók',
    route: {
      path: '/videok/:page?',
      exact: true,
    },
    component: {
      type: 'CategoryPage',
      props: {
        match: { params: { category: 'videok' } },
      },
    },
  },

  Gallery: {
    title: 'Galéria',
    route: {
      path: '/galeria/:slug?',
    },
    component: {
      type: 'GalleryPage',
    },
  },

  Search: {
    title: 'Keresés',
    route: {
      path: '/kereses',
      exact: true,
    },
    component: {
      type: 'SearchPage',
    },
  },

  Success: {
    title: 'Sikeres művelet',
    route: {
      path: '/siker-muvelet/:post',
    },
    component: {
      type: 'PostPage',
      props: {
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  TermsOfUse: {
    title: 'Felhasználási feltételek',
    mobileOnly: true,
    sitemap: {
      slug: 'felhasznalasi-feltetelek',
    },
    route: {
      path: '/felhasznalasi-feltetelek',
    },
    component: {
      type: 'PostPage',
      props: {
        match: { params: { post: 'felhasznalasi-feltetelek' } },
        skipBreadcrumb: true,
        skipHeader: true,
      },
    },
  },

  Post: {
    title: 'Bejegyzés',
    route: {
      path: '/:post',
      exact: true,
    },
    component: {
      type: 'PostPage',
    },
  },

  Home: {
    title: 'Kezdőoldal',
    route: {
      path: '/:category?/:page?',
      exact: true,
    },
    component: {
      type: 'CategoryPage',
      props: {
        match: { params: { category: 'fooldal' } },
        showCategoryName: false,
        imgSrc: 'resources/images/landing.jpg',
      },
    },
  },

};

export default Pages;
