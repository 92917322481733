import { find, identity } from 'lodash';

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { Segment, SegmentUrl, RealSegment, IS_BROWSER, SEGMENT_COOKIE_NAME } from 'consts';

const defaultSegmentGlobal = Segment.PORTAL;

const SegmentProvider = ({ cookies, children }) => {
  const [activeSegment, defaultSegment] = (cookies[SEGMENT_COOKIE_NAME] || '').split(':');
  const state = useState({
    activeSegment: RealSegment[activeSegment] || defaultSegmentGlobal,
    defaultSegment: RealSegment[defaultSegment] || defaultSegmentGlobal,
  });

  return (
    <SegmentProvider.Context.Provider value={state}>
      {children}
    </SegmentProvider.Context.Provider>
  );
};

SegmentProvider.Context = createContext([defaultSegmentGlobal, identity]);

SegmentProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  cookies: PropTypes.shape({}),
};

SegmentProvider.defaultProps = {
  cookies: {},
};

export const useSegment = (forceSegment) => {
  const { pathname } = useLocation();
  const segmentState = useContext(SegmentProvider.Context);
  const [{ activeSegment, defaultSegment }, setSegment] = segmentState;

  const setActiveSegment = useCallback((segment) => {
    const expireDate = new Date();
    expireDate.setYear(1900 + expireDate.getYear() + 1);
    const newActiveSegment = RealSegment[segment] || Segment.PORTAL;

    if (IS_BROWSER) {
      document.cookie = `${SEGMENT_COOKIE_NAME}=${newActiveSegment}:${defaultSegment}; path=/; expires=${expireDate.toUTCString()}`;
    }
    setSegment({ activeSegment: newActiveSegment, defaultSegment });
  }, [defaultSegment, setSegment]);

  const setDefaultSegment = useCallback((segment) => {
    const expireDate = new Date();
    expireDate.setYear(1900 + expireDate.getYear() + 1);
    const newDefaultSegment = RealSegment[segment] || Segment.PORTAL;
    if (IS_BROWSER) {
      document.cookie = `${SEGMENT_COOKIE_NAME}=${activeSegment}:${newDefaultSegment}; path=/; expires=${expireDate.toUTCString()}`;
    }
    setSegment({ activeSegment, defaultSegment: newDefaultSegment });
  }, [activeSegment, setSegment]);

  const locationSegment = find(Segment, (segment) => pathname.startsWith(SegmentUrl[segment]));
  useEffect(() => {
    if (locationSegment && locationSegment !== activeSegment) {
      setActiveSegment(locationSegment);
    }
  }, [locationSegment, activeSegment, setActiveSegment]);

  // handle forceSegment
  useEffect(() => {
    if (forceSegment && activeSegment !== forceSegment) {
      setActiveSegment(forceSegment);
    }
  }, [activeSegment, setActiveSegment, forceSegment]);

  return {
    activeSegment: locationSegment && locationSegment !== activeSegment ? locationSegment : activeSegment,
    setActiveSegment,
    defaultSegment,
    setDefaultSegment,
    forcedActive: forceSegment && forceSegment === activeSegment,
  };
};

export const withSegment = (WrappedComponent) => (props) => {
  const segment = useSegment();
  return <WrappedComponent segment={segment} {...props} />;
};

export default SegmentProvider;
