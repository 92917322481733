import { each } from 'lodash';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import Axios from 'axios';
import LRU from 'lru-cache';

import { IS_BROWSER } from 'consts';
import { getLocation } from 'utils';

const actions = {
  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END',
};

const createAxiosCache = (options) => {
  const ssrPromises = [];
  const dispatchersList = {};

  const getDispatchers = (cacheKey) => dispatchersList[cacheKey] || [];

  const removeDispatchers = (cacheKey) => {
    dispatchersList[cacheKey] = [];
  };

  const addDispatcher = (cacheKey, dispatch) => {
    const dispatchers = getDispatchers(cacheKey);
    dispatchers.push(dispatch);
    dispatchersList[cacheKey] = dispatchers;
  };

  const removeDispatcher = (cacheKey, dispatch) => {
    dispatchersList[cacheKey] = getDispatchers(cacheKey).filter((item) => item !== dispatch);
  };

  let cache = new LRU();
  let axios = Axios;

  let cacheKeySerializer = ({ url, method, params, data }) => {
    const { pathname, search } = getLocation(url) || {};
    const serialized = [pathname, search, method.toLowerCase(), params, data].map((value) => JSON.stringify(value)).join('|');
    return serialized;
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case actions.REQUEST_START:
        return {
          ...state,
          success: false,
          loading: true,
        };

      case actions.REQUEST_END:
        return {
          ...state,
          loading: false,
          success: action.success,
          ...(action.error ? {} : { data: action.payload.data }),
          [action.error ? 'error' : 'response']: action.payload,
        };

      default:
        return state;
    }
  };

  const request = async (config, dispatch, state) => {
    const cacheKey = cacheKeySerializer(config);
    const hit = cache.get(cacheKey);
    if (hit) {
      return;
    }

    try {
      dispatch({ type: actions.REQUEST_START });
      cache.set(cacheKey, reducer(state, { type: actions.REQUEST_START }));

      const response = await axios(config);
      const responseForCache = { ...response };
      delete responseForCache.config;
      delete responseForCache.request;

      const action = { type: actions.REQUEST_END, success: true, payload: responseForCache, cacheKey };
      cache.set(cacheKey, reducer(state, action));
      each(getDispatchers(cacheKey), (dispatcher) => dispatcher(action));
      removeDispatchers(cacheKey);
    } catch (err) {
      // in case of request error we delete the cache -- client could try it again
      const { response } = err;
      const errForCache = {
        ...err,
        response: response ? { ...err.response } : {},
      };
      delete errForCache.config;
      delete errForCache.request;
      delete errForCache.response.config;
      delete errForCache.response.request;

      if (axios.isCancel(err)) {
        return;
      }

      const action = { type: actions.REQUEST_END, payload: errForCache, error: true, cacheKey };
      cache.set(cacheKey, reducer(state, action));
      each(getDispatchers(cacheKey), (dispatcher) => dispatcher(action));
      removeDispatchers(cacheKey);
    }
  };

  const configure = (opts = {}) => {
    if (opts.axios) {
      ({ axios } = opts);
    }

    if (opts.cache) {
      ({ cache } = opts);
    }

    if (opts.cacheKeySerializer) {
      ({ cacheKeySerializer } = opts);
    }
  };

  const loadCache = (data) => cache.load(data);

  const serializeCache = async () => {
    await Promise.all(ssrPromises);

    ssrPromises.length = 0;

    return cache.dump();
  };

  const useAxiosCache = (cfg) => {
    const config = typeof cfg === 'string' ? { method: 'get', url: cfg } : cfg;
    const cacheKey = cacheKeySerializer(config);
    const initialState = cache.get(cacheKey) || { loading: false };
    const [state, dispatch] = useReducer(reducer, initialState);

    const { loading, error, response } = state;
    const ssrState = useMemo(() => {
      if (!IS_BROWSER && !loading && !error && !response) {
        const canceller = axios.CancelToken.source();

        const promise = request({
          ...config,
          cancelToken: canceller.token,
        }, dispatch, state);

        ssrPromises.push(promise);

        return state;
      }
      return state;
    }, [loading, error, response, cacheKey, dispatch]); // eslint-disable-line

    useEffect(() => {
      const cachedState = cache.get(cacheKey);
      if (cachedState) {
        if (cachedState.loading) {
          addDispatcher(cacheKey, dispatch);
          return () => {
            removeDispatcher(cacheKey, dispatch);
          };
        }
        return undefined;
      }
      cache.del(cacheKey);

      addDispatcher(cacheKey, dispatch);
      const canceller = axios.CancelToken.source();
      request({
        ...config,
        cancelToken: canceller.token,
      }, dispatch);

      return () => {
        removeDispatcher(cacheKey, dispatch);
      };

    }, [cacheKey, dispatch]); //eslint-disable-line

    const refetch = useCallback(() => {
      cache.del(cacheKey);
      const canceller = axios.CancelToken.source();
      request({
        ...config,
        cancelToken: canceller.token,
      }, dispatch);
    }, [cacheKey, dispatch]); // eslint-disable-line

    const clearCache = useCallback(() => {
      cache.del(cacheKey);
    }, [cacheKey]);

    return [IS_BROWSER ? state : ssrState, refetch, clearCache];
  };

  configure(options);

  return {
    configure,
    loadCache,
    serializeCache,
    useAxiosCache,
  };
};

const instance = createAxiosCache();

const { configure, loadCache, serializeCache, useAxiosCache } = instance;
export { configure, loadCache, serializeCache, useAxiosCache, createAxiosCache };

export default createAxiosCache;
