import { IS_BROWSER } from 'consts';

export const getStackTrace = () => {
  Error.stackTraceLimit = 15;
  let stack;
  try {
    throw new Error('');
  } catch (error) {
    stack = error.stack || '';
  }
  stack = stack.split('\n').map((line) => line.trim());
  return stack.splice(stack[0] === 'Error' ? 2 : 1);
};

const debug = global ? false : window && window.location && window.location.hash && window.location.hash.indexOf('#debug') >= 0;

if (IS_BROWSER && debug) {
  window.localStorage.setItem('debug', 1);
}

const logEnabled = global ? false : debug || (window && window.localStorage && window.localStorage.getItem('debug'));

/**
 * This is a default logger which wraps all the native console interface.
 * It is used to allow easy overriding of the native console behaviour.
 */
function logger(origConsole = (global || window).console) {
  return {
    ...origConsole,
    stack: () => (logEnabled ? origConsole.debug(getStackTrace()) : undefined),
    debug: (...args) => (logEnabled ? origConsole.debug(...args) : undefined),
    log: (...args) => (logEnabled ? origConsole.log(...args) : undefined),
    info: (...args) => (logEnabled ? origConsole.info(...args) : undefined),
    warn: (...args) => (logEnabled ? origConsole.warn(...args) : undefined),
    error: (...args) => (logEnabled ? origConsole.error(...args) : undefined),
    group: (...args) => (logEnabled ? origConsole.group(...args) : undefined),
    groupCollapsed: (...args) => (logEnabled ? origConsole.groupCollapsed(...args) : undefined),
  };
}

export default logger();
