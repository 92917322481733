import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { usePostSearch } from 'contexts';
import { getQueryParam, getSearchUrl } from 'utils';
import { Pagination, PostTile, SearchForm } from 'view/components';
import { MainView } from 'view/modules';

import './SearchPage.scss';
import './SearchPage.responsive.scss';

const SearchPage = ({ className, location: { search }, showSearch }) => {
  const [searchQuery, page] = getQueryParam(search, ['s', 'page']);
  const { posts, pages } = usePostSearch(searchQuery, page);

  // useEffect(() => {
  //   window.fbq('track', 'Search');
  // }, []);

  return (
    <MainView fullWidth>
      <div className={clsx(className, 'search-page')}>
        {showSearch && <SearchForm searchQuery={searchQuery} />}
        <div className="head">Keresési találatok: {searchQuery}</div>
        {posts && (
          <React.Fragment>
            {!posts.length && <div className="search-empty-list">Nem találtunk megjeleníthető tartalmat.</div>}
            <section className="posts">
              {posts.map((post, idx) => <PostTile className={clsx({ even: idx % 2 })} key={post.id} showCategory {...post} image={post.imageMediaId} />)}
            </section>
            <Pagination basePath={getSearchUrl({ s: searchQuery, page: { raw: ':page?' } })} pages={pages} page={page} />
          </React.Fragment>
        )}
      </div>
    </MainView>
  );
};

SearchPage.propTypes = {
  ...ReactRouterPropTypes,
  className: PropTypes.string,
  showSearch: PropTypes.bool,
};

SearchPage.defaultProps = {
  className: '',
  showSearch: false,
};

export default withRouter(SearchPage);
