import React from 'react';
import PropTypes from 'prop-types';

import { ChildrenPropType } from 'consts';
import { NavLink } from 'react-router-dom';

const Link = ({ to, children, ...props }) => {
  if (!to) {
    return children;
  }

  const blank = to[0] === '+';
  const href = blank ? to.substr(1) : to;
  if (href.startsWith('http://') || href.startsWith('https://')) {
    return <a href={href} target={blank ? '_blank' : undefined} rel="noopener noreferrer" {...props}>{children}</a>;
  }

  return <NavLink to={href} {...props}>{children}</NavLink>;
};

Link.propTypes = {
  to: PropTypes.string,
  children: ChildrenPropType.isRequired,
};

Link.defaultProps = {
  to: undefined,
};

export default Link;
