import { set } from 'lodash';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { usePosts } from 'contexts';
import logger from 'utils/logger';
import { PostTile } from 'view/components';
import { MainView } from 'view/modules';

import './MainPage.scss';
import './MainPage.responsive.scss';

const allocateNextFree = (result, size) => (post) => {
  const priority = Math.max(0, post.priority - 1);
  for (let idx = 0; idx < size; idx += 1) {
    const pos = (priority + idx) % size;
    if (!result[pos]) {
      return set(result, pos, post);
    }
  }
  return false;
};

const calculatePostOrder = (posts) => {
  const result = [];

  const awaitingPosts = posts
    .filter(({ priority }) => priority > 0)
    .map((post) => {
      const priority = post.priority - 1;
      if (result[priority]) {
        return post;
      }
      set(result, priority, post);
      return null;
    })
    .filter((post) => !!post);

  const nonPriorizedPosts = posts.filter(({ priority }) => priority <= 0);
  awaitingPosts.forEach(allocateNextFree(result, posts.length));
  nonPriorizedPosts.forEach(allocateNextFree(result, posts.length));
  logger.info('Post orders calculated:', result.map(({ id, priority, slug }) => ({ id, priority, slug })));
  return result;
};

const MainPage = ({ className/* , ...props */ }) => {
  const { posts } = usePosts();

  const orderedPosts = useMemo(() => calculatePostOrder(posts || []), [posts]);

  return (
    <MainView loaded={!!posts} imgSrc="resources/images/landing.jpg" fullWidth>
      <div className={clsx(className, 'main-page')}>
        <section className="latest-articles">
          {orderedPosts.map((post, idx) => <PostTile key={post.id} className={clsx(`post-${idx}`, { even: idx % 2 })} {...post} />)}
        </section>
      </div>
    </MainView>
  );
};

MainPage.propTypes = {
  className: PropTypes.string,
};

MainPage.defaultProps = {
  className: '',
};

export default MainPage;
