import { Segment } from './shared';

export * from './prop-types';
export * from './shared';

export { default as servicesConfig } from './services';

export const ImageWidth = {
  banner: 280,
  categories: 396,
  facebook: 888,
  popup: 800,
  twitter: 280,
  gallery: 240,
  related: 200,
};

export const HOST_URL = (process && process.env && process.env.SULTANA_HOST_URL) || 'https://sultana.com';

export const BASE_URL = '/api';

export const IS_BROWSER = typeof window !== 'undefined';

export const SegmentText = {
  [Segment.PORTAL]: 'Portál',
};

export const BannerPosition = {
  POST_1: 'POST_1',
  POST_2: 'POST_2',
  POST_3: 'POST_3',
  POPUP_AT_LOAD: 'POPUP_AT_LOAD',
  SIDE_AFTER_BLOGS: 'SIDE_AFTER_BLOGS',
  SIDE_BEFORE_BLOGS: 'SIDE_BEFORE_BLOGS',
  SIDE_BEFORE_CATEGORY: 'SIDE_BEFORE_CATEGORY',
};

export const SegmentsList = [Segment.PORTAQL].map((segment) => ({ segment, text: SegmentText[segment] }));
