import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './MainView.scss';
import './MainView.responsive.scss';

let lastHeight = 'auto';
const MainView = ({ className, children, loaded, imgSrc, fullWidth }) => {
  // anti-flicker solution: keep min-height until next page is loaded
  const ref = useRef('auto');
  const [, setContentHeight] = useState({});
  useEffect(() => {
    if (!loaded) {
      return;
    }
    // wait for next rendering cycle
    setTimeout(() => {
      lastHeight = ref.current?.scrollHeight;
      setContentHeight({});
    }, 0);
  }, [loaded]);

  return (
    <div className={clsx('main', className, { 'full-width': fullWidth })} style={{ minHeight: lastHeight }}>
      {imgSrc && (
        <div className="main-image">
          <img src="resources/images/landing.jpg" alt="" />
        </div>
      )}
      <div className="main-inner" ref={ref}>
        {children}
      </div>
    </div>
  );
};
MainView.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  loaded: PropTypes.bool,
  imgSrc: PropTypes.string,
  fullWidth: PropTypes.bool,
};

MainView.defaultProps = {
  className: '',
  loaded: false,
  imgSrc: null,
  fullWidth: false,
};

export default MainView;
