import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { Button, Link } from 'view/base';
import { getImageUrl } from 'utils';

import { usePopup, usePost, useTransition } from '../contexts';

import './Popup.scss';
import { PostContent } from './modules';

const PopupPostContent = ({ postSlug, ...props }) => {
  const { post } = usePost(postSlug || null);
  return post ? <PostContent post={post} {...props} /> : '';
};

PopupPostContent.propTypes = {
  postSlug: PropTypes.string,
};

PopupPostContent.defaultProps = {
  postSlug: null,
};

const Popup = () => {
  const { popup, showNextPopup } = usePopup();
  const { enter, leave, ...transition } = useTransition();
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.classList[popup ? 'add' : 'remove']('no-scroll');
  }, [popup]);

  const onCloseClick = useCallback(async () => {
    await leave();
    showNextPopup();
  }, [leave, showNextPopup]);

  const onImageLoad = useCallback(() => {
    enter();
  }, [enter]);

  const [, postSlug] = popup?.target.match(/^\((.+)\)$/) || [];
  useEffect(() => {
    if (!postSlug) {
      return;
    }
    enter();
  }, [enter, postSlug]);

  const history = useHistory();
  useEffect(() => history.listen(() => onCloseClick()), [history, onCloseClick, popup]);

  if (!popup) {
    return '';
  }

  return (
    <div className="popup-mask">
      <div className={clsx('popup', transition)}>
        {postSlug && <PopupPostContent className="popup-post-content" postSlug={postSlug} skipBreadcrumb skipHeader skipGallery />}
        {!postSlug && (
          <Link to={popup.target} title={popup.name} onClick={onCloseClick}>
            <img className="popup-image" src={getImageUrl(popup.imageMediaId, null, 'popup')} alt={popup.name} title={popup.name} onLoad={onImageLoad} />
          </Link>
        )}
        <Button className="toggle-button material-icons" onClick={onCloseClick} title="Ablak bezárása">close</Button>
      </div>
    </div>
  );
};

export default Popup;
