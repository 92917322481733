import React, { useCallback, useMemo } from 'react';
import { find, map } from 'lodash';
import PropTypes from 'prop-types';
import { generatePath, NavLink, Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { useGalleries } from 'contexts';
import { Gallery, MainView } from 'view/modules';

import './GalleryPage.scss';
import clsx from 'clsx';
import { getImageUrl } from 'utils';

const orderSorterFn = (mediaA, mediaB) => mediaA?.GalleryMedia?.order - mediaB?.GalleryMedia?.order;

const GalleryPage = ({ className, match }) => {
  const { galleries, error } = useGalleries();

  const { params: { slug: gallerySlug }, path } = match;
  const sortedGalleries = useMemo(() => galleries?.map((gallery) => ({
    ...gallery,
    media: (gallery.media || []).sort(orderSorterFn),
  })), [galleries]);

  const selectedGallery = useMemo(() => find(sortedGalleries || [], (gallery) => gallery.slug === gallerySlug), [sortedGalleries, gallerySlug]);
  const renderGalleryItem = useCallback(({ id, media, title, slug }) => {
    if (!media?.length) {
      return '';
    }

    return (
      <NavLink key={id} className="gallery-item" to={generatePath(path, { slug })}>
        <img src={getImageUrl(media[0], false, 'gallery')} alt={title} />
        <div className="title">{title}</div>
      </NavLink>
    );
  }, [path]);

  if (galleries === null || error) {
    // ... and return 404 or redirec to root page
    return <Redirect to="/" />;
  }

  if (galleries && !selectedGallery && gallerySlug) {
    return <Redirect to={generatePath(path)} />;
  }

  return (
    <MainView className={clsx({ 'bg-gallery': selectedGallery })} loaded={!!galleries}>
      <div className={clsx('gallery-page', className, { list: !selectedGallery })}>
        {!selectedGallery && galleries && map(sortedGalleries, renderGalleryItem)}
        {selectedGallery && <Gallery gallery={selectedGallery} />}
      </div>
    </MainView>
  );
};

GalleryPage.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,

};

GalleryPage.defaultProps = {
  className: '',

};

export default GalleryPage;
