import { mapKeys } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { BannerPosition, CategoryStatus } from 'consts';
import { useSegment, useToggledCategory } from 'contexts';
import { Button } from 'view/base';
import { findCategoryById, getCategoryUrl } from 'utils';

import Banners from '../Banners/Banners';
import Post from '../Post/Post';

import './CategoryMenu.scss';

const MenuList = (props) => {
  const { categories, allCategories, allowToggle, opened, heights, rootUrl, onMenuClick } = props;
  const { activeSegment } = useSegment();

  return categories
    .filter((category) => category.segments.indexOf(activeSegment) >= 0)
    .map(({ id, color, latestPost, attributes }) => {
      const category = findCategoryById(allCategories, id);
      const { name, slug } = category;
      const subCategories = allCategories.filter(({ parentId, status }) => parentId === id && status !== CategoryStatus.HIDDEN);
      const isOpened = subCategories.length && (!allowToggle || opened[id]);

      return (
        <React.Fragment key={id}>
          {slug === 'blogok' && <Banners positionId={BannerPosition.SIDE_BEFORE_BLOGS} />}
          {attributes?.dividers?.before && <div className="divider" />}
          <li className={clsx('menu-item', { opened: isOpened })}>
            <div className="menu-content">
              <NavLink to={getCategoryUrl({ slug, segment: activeSegment, rootUrl })} className="menu-text" style={{ color }}>{name}</NavLink>
              {!!subCategories.length && allowToggle && (
              <Button className="menu-arrow material-icons" onClick={onMenuClick(id)}>
                {isOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </Button>
              )}
            </div>
            {!subCategories.length && latestPost && <Post post={latestPost} imageSize="categories" />}
            {!!subCategories.length && (
            <ul className="menu-items" data-category-menu-id={id} style={{ maxHeight: `${isOpened ? heights[id] : 0}px` }}>
              {latestPost && <Post post={latestPost} imageSize="categories" />}
              <MenuList {...props} categories={subCategories} />
            </ul>
            )}
          </li>
          {attributes?.dividers?.after && <div className="divider" />}
        </React.Fragment>
      );
    });
};

MenuList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const CategoryMenu = ({ className }) => {
  const [heights, setHeights] = useState({});
  const { categories, toggle, opened, allowToggle, rootUrl } = useToggledCategory();
  const ref = useRef();

  const onMenuClick = useCallback((id) => () => toggle(id), [toggle]);

  const categoryMap = useMemo(() => mapKeys(categories, (category) => category.id), [categories]);

  useEffect(() => {
    if (!ref) {
      return;
    }

    setHeights((prevHeights) => {
      const newHeights = {};

      const nodes = ref.current.querySelectorAll('[data-category-menu-id]');
      let hasChanged = false;
      for (let idx = 0; idx < nodes.length; idx += 1) {
        const node = nodes[idx];
        const id = node.getAttribute('data-category-menu-id');
        const height = prevHeights[id] || 0;
        const newHeight = opened[id] ? node.scrollHeight : 0;
        if (height !== newHeight) {
          const diff = newHeight - height;
          newHeights[id] = newHeight;
          hasChanged = true;

          let categoryId = id;
          do {
            const category = categoryMap[categoryId];
            categoryId = category && category.parentId;
            if (categoryId) {
              newHeights[categoryId] = (prevHeights[categoryId] || diff) + diff;
            }
          } while (categoryId);
        }
      }

      return hasChanged ? { ...prevHeights, ...newHeights } : prevHeights;
    });
  }, [opened, ref, categoryMap]);

  const rootCategories = (categories || []).filter(({ parentId, status }) => !parentId && status !== CategoryStatus.HIDDEN);

  return (
    <div ref={ref} className={clsx('category-menu', className)}>
      <ul className="menu-list">
        <MenuList
          allCategories={categories}
          categories={rootCategories}
          onMenuClick={onMenuClick}
          opened={opened}
          heights={heights}
          allowToggle={allowToggle}
          rootUrl={rootUrl}
        />
      </ul>
    </div>
  );
};

CategoryMenu.propTypes = {
  className: PropTypes.string,
};

CategoryMenu.defaultProps = {
  className: '',
};

export default CategoryMenu;
