import { each, findIndex } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect } from 'react-router-dom';

import { Pages } from 'consts';
import { useDynamicModules, usePostSurvey } from 'contexts';
import { Button } from 'view/base';
import { processSurvey } from 'utils';

import SurveyQuestion from './SurveyQuestion';
import PrivacyAgreementForm from './PrivacyAgreementForm';

import './Survey.scss';

const modulesMap = {
  AGREEMENT: PrivacyAgreementForm,
};

const messageMap = {
  'survey.answers.count.below.limit': 'Kérem jelöljön be legalább {minCount} választ!',
  'survey.answers.count.above.limit': 'A megengedettnél ({maxCount}) több választ jelölt be!',
};

const Survey = ({ postId, postSlugSuccess, privacy, content, onRedirect }) => {
  const [privacyAgreed, setPrivacyAggreed] = useState();
  const [surveyStarted, setSurveyStart] = useState(!privacy);
  const [responses, setResponses] = useState({});
  const [errors, setErrors] = useState({ questions: [], common: [] });
  const questions = useMemo(() => processSurvey(content), [content]);
  const onAgreeChange = useCallback(({ value }) => {
    setPrivacyAggreed(value);
  }, []);

  const onSubmitPrivacy = useCallback(() => {
    setSurveyStart(true);
  }, []);

  const { loading, error: requestError, result, postSurvey } = usePostSurvey();
  const onSubmitSurvey = useCallback(async (/* event */) => {
    const responsesData = questions.map(({ answers, title }, questionIdx) => {
      const responseAnswers = answers
        .map((answer, answerIdx) => responses[`${answer.questionIdx}-${answerIdx}`] && answer)
        .filter((item) => item);

      return { questionIdx, title, answers: responseAnswers };
    });

    postSurvey({ postId, privacyAgreed, responses: responsesData.filter((item) => item) });
  }, [postId, postSurvey, privacyAgreed, responses, questions]);

  const externalProps = useMemo(
    () => ({ privacyAgreed, onAgreeChange, onSubmitPrivacy }),
    [privacyAgreed, onAgreeChange, onSubmitPrivacy],
  );
  const { html: privacyHtml, ref } = useDynamicModules(privacy, { main: false, externalProps, modulesMap });
  useEffect(() => {
    if (!surveyStarted) {
      return;
    }
    window.scrollTo({
      top: ref.current.offsetTop - 12,
      left: 0,
      behavior: 'smooth',
    });
  }, [ref, surveyStarted]);

  const onAnswerChange = useCallback((value, { answer/* , checked */ }) => {
    setResponses(value);
    setErrors((prevErrors) => {
      const questionErrors = [...prevErrors.questions];
      delete questionErrors[answer.questionIdx];
      return { questions: questionErrors, common: [] };
    });
  }, []);

  useEffect(() => {
    if (!requestError) {
      return;
    }
    const messages = requestError?.response?.data?.messages || [];
    setErrors((/* prevErrors */) => {
      const questionErrors = [];
      const commonErrors = [];
      each(messages, (message) => {
        const { name, text, params, params: { questionIdx } } = message;
        const messageText = messageMap[name] || `A művelet végrehajtása során nem várt hiba történt (${text})!`;
        const finalText = messageText.replace(/\{(.+?)\}/ig, ($, paramId) => params[paramId] || '');
        if (questions[questionIdx]) {
          questionErrors[questionIdx] = questionErrors[questionIdx] || finalText;
        } else {
          commonErrors.push(finalText);
        }
      });

      const errorQuestionIdx = findIndex(questionErrors);
      const dom = errorQuestionIdx >= 0 && ref.current.querySelector(`.survey-question:nth-child(${errorQuestionIdx + 1})`);
      if (dom) {
        dom.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }

      return { questions: questionErrors, common: commonErrors };
    });
  }, [requestError, questions, ref]);

  const valid = useMemo(() => !errors.questions.filter((item) => item).length && !errors.common.length, [errors]);

  useEffect(() => {
    if (!result || !onRedirect) {
      return;
    }
    onRedirect(postSlugSuccess ? generatePath(Pages.SubscriptionSuccess.route.path, { post: postSlugSuccess }) : '/');
  }, [result, postSlugSuccess, onRedirect]);

  if (result && !onRedirect) {
    return <Redirect to={postSlugSuccess ? generatePath(Pages.SubscriptionSuccess.route.path, { post: postSlugSuccess }) : '/'} />;
  }

  return (
    <div className="survey" ref={ref}>
      {!surveyStarted && (
        <div
          className="privacy ql-editor content"
          dangerouslySetInnerHTML={{ __html: privacyHtml }} /* eslint-disable-line react/no-danger */
        />
      )}
      {content && surveyStarted && (
        <div className="survey-questions">
          {questions.map(({ type, title, subtitle, answers }, questionIdx) => (
            <SurveyQuestion
              key={title}
              type={type}
              title={title}
              subtitle={subtitle}
              answers={answers}
              error={errors.questions[questionIdx]}
              value={responses}
              onChange={onAnswerChange}
            />
          ))}
          {errors.common.map((error) => <div className="error">{error}</div>)}
          <Button type="submit" className="green text" disabled={loading || !valid} onClick={onSubmitSurvey}>Kérdőív beküldése</Button>
        </div>
      )}
    </div>
  );
};

Survey.propTypes = {
  postId: PropTypes.number.isRequired,
  postSlugSuccess: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  privacy: PropTypes.string,
  onRedirect: PropTypes.func,
};

Survey.defaultProps = {
  privacy: '',
  onRedirect: undefined,
};

export default Survey;
