import { isPlainObject, map } from 'lodash';
import { generatePath } from 'react-router-dom';

import { Pages } from 'consts';

const calcQueryParams = (params) => {
  const paramsList = map(params, (value, name) => (
    value ? `${name}=${isPlainObject(value) && value.raw ? value.raw : encodeURIComponent(value)}` : undefined
  )).filter((param) => !!param);

  return paramsList.length ? `?${paramsList.join('&')}` : '';
};

export const getPostUrl = ({ slug, segment }) => generatePath(Pages.Post.route.path, { post: slug, segment });

export const getCategoryUrl = ({ slug, page, segment, rootUrl = undefined }) => (
  generatePath(rootUrl || Pages.Category.route.path, { category: slug, page, segment })
);

export const getTagUrl = ({ slug, page, segment }) => generatePath(Pages.Tag.route.path, { tag: slug, page, segment });

export const getSearchUrl = (params) => `${generatePath(Pages.Search.route.path)}${calcQueryParams(params)}`;
