import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { IS_BROWSER } from 'consts';
import { useSegment } from 'contexts';
import { getPostUrl } from 'utils';

const PostRedirect = ({ post: { slug, redirectUrl, segment } }) => {
  const { activeSegment, setActiveSegment } = useSegment();

  if (redirectUrl) {
    const blank = redirectUrl[0] === '+';

    if (IS_BROWSER) { // have to use typeof because of node and browser duality
      window.location = blank ? redirectUrl.substr(1) : redirectUrl;
      return '';
    }

    return <Redirect to={blank ? redirectUrl.substr(1) : redirectUrl} />;
  }

  if (activeSegment !== segment) {
    setActiveSegment(segment);
  }

  return <Redirect to={getPostUrl({ slug, segment: segment || activeSegment })} />;
};

PostRedirect.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string,
    redirectUrl: PropTypes.string,
    segment: PropTypes.string,
  }).isRequired,
};

export default PostRedirect;
