import { useCachedService, useService } from './ServiceProvider';
import { useSegment } from './SegmentProvider';

export const useCategories = () => {
  const [{ loading, error, data: { categories } = {} }, refetch] = useCachedService('getCategories');
  return { loading, error, refetch, categories: error ? null : categories };
};

export const useBanners = () => {
  const [{ loading, error, data: { banners } = {} }, refetch] = useCachedService('getBanners');
  return { loading, error, banners: error ? null : banners, refetch };
};

export const useCategory = (categoryId) => {
  const { activeSegment } = useSegment();
  const [{ loading, error, data: { category } = {} }, refetch] = useCachedService('getCategory', { categoryId, segment: activeSegment });
  return { loading, error, category: error ? null : category, refetch };
};

export const usePost = (slug) => {
  const [{ loading, error, data: { post, related } = {} }, refetch, clearCache] = useCachedService('getPostBySlug', { slug });
  return { loading, error, post: error ? null : post, related, refetch, clearCache };
};

export const usePostSearch = (query, page) => {
  const [{ loading, error, data: { posts, pages = 1 } = {} }, refetch] = useCachedService('getPostsBySearch', { query, page });
  return { loading, error, posts: error ? null : posts, pages, refetch };
};

export const usePosts = () => {
  const { activeSegment } = useSegment();
  const [{ loading, error, data: { posts, categoryPosts } = {} }, refetch, clearCache] = useCachedService('getPosts', { segment: activeSegment });
  return {
    loading, error, refetch, clearCache,
    posts: error ? null : posts,
    categoryPosts: error ? null : categoryPosts,
  };
};

export const usePostsByCategorySlug = (slug, page) => {
  const { activeSegment } = useSegment();
  const [{ loading, error, data: { posts = [], pages = 1 } = {} }, refetch] =
    useCachedService('getPostsByCategorySlug', { slug, page, segment: activeSegment });
  return { loading, error, posts: error ? null : posts, pages, refetch };
};

export const usePostsByTagSlug = (slug, page) => {
  const { activeSegment } = useSegment();
  const [{ loading, error, data: { posts = [], pages = 1 } = {} }, refetch] = useCachedService('getPostsByTagSlug', { slug, page, segment: activeSegment });
  return { loading, error, posts: error ? null : posts, pages, refetch };
};

export const useTag = (slug) => {
  const { activeSegment } = useSegment();
  const [{ loading, error, data: { tag } = {} }, refetch] = useCachedService('getTagBySlug', { slug, segment: activeSegment });
  return { loading, error, tag: error ? null : tag, refetch };
};

export const useTagCloud = () => {
  const { activeSegment } = useSegment();
  const [{ loading, error, data: { tags } = {} }, refetch] = useCachedService('getTagCloud', { segment: activeSegment });
  return { loading, error, tags: error ? null : tags, refetch };
};

export const useSubscription = (options) => {
  const [{ loading, error, data }, subscribeByEmail] = useService('postSubscription', options);
  return { loading, error, subscription: error ? null : data && data.result, subscribeByEmail };
};

export const usePostVote = (options) => {
  const [{ loading, error, data }, postVote] = useService('postVote', options);
  return { loading, error, result: error ? null : data && data.result, postVote };
};

export const usePostSurvey = (options) => {
  const [{ loading, error, data }, postSurvey] = useService('postSurvey', options);
  return { loading, error, result: error ? null : data && data.result, postSurvey };
};

export const useMedia = (slug) => {
  const [{ loading, error, data: { media } = {} }, refetch] = useCachedService('getMediaBySlug', { slug });
  return { loading, error, media: error ? null : media, refetch };
};

export const usePostsByPollId = (pollId) => {
  const [{ loading, error, data: { posts } = {} }, refetch, clearCache] = useCachedService('getPostsByPollId', { pollId });
  return { loading, error, posts: error ? null : posts, refetch, clearCache };
};

export const useGalleries = (options) => {
  const [{ loading, error, data: { galleries } = {} }, refetch] = useCachedService('getGalleries', options);
  return { loading, error, galleries: error ? null : galleries, refetch };
};
