/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect } from 'react-router-dom';

import { Pages } from 'consts';
import { usePost, useSubscription } from 'contexts';
import { Button } from 'view/base';

import './Subscription.scss';

const Checkbox = typeof window !== 'undefined' ? require('react-simple-checkbox').default : () => '';

// eslint-disable-next-line max-len
const regexpEmailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexpFullnameValidator = /^(?:.+?)\s+(?:.+?)$/i;
const regexpOrganizationValidator = /^(?:.+?){2,}$/i;

const messageMap = {
  'validation.error.not-unique': 'Ezzel az e-mail címmel már fel vagy iratkozva!',
};

const getError = (error) => {
  const message = error && error.messages && error.messages[0];
  const name = message && message.name;
  return (messageMap[name]) || `A feliratkozás rendszerporbléma miatt jelenleg nem működik (${name || 'ismeretlen kód'})!`;
};

const onPrivacyInfoClick = (event) => {
  event.preventDefault();
  window.open(generatePath(Pages.PrivacyInfo.route.path), 'previewWindow', 'menubar=0,toolbar=0,width=1280,height=768');
};

const defaultFieldValues = {
  email: '',
};
const Subscription = ({
  type, postSlug, postSlugSuccess, showFullname, showOrganization, showPhone, showPrivacyInfo,
  emailInfo, emailSubscriberPostSlug, newsletter, newsletterRequired, button, onRedirect,
}) => {
  const [fieldValues, setFieldValues] = useState(defaultFieldValues);
  const onInputChange = useCallback(({ currentTarget: { id, value } }) => {
    setFieldValues((fieldValuesOld) => ({ ...fieldValuesOld, [id]: value }));
  }, []);

  const [privacyInfoRead, setPrivacyInfoRead] = useState(!showPrivacyInfo);
  const onPrivacyInfoRead = useCallback((/* ev */) => {
    setPrivacyInfoRead(!privacyInfoRead);
  }, [privacyInfoRead, setPrivacyInfoRead]);

  const [allowReceivingNewsletter, setAllowReceivingNewsletter] = useState(false);
  const onAllowReceivingNewsletterChange = useCallback((/* ev */) => {
    setAllowReceivingNewsletter(!allowReceivingNewsletter);
  }, [allowReceivingNewsletter, setAllowReceivingNewsletter]);

  const { loading, error, subscription, subscribeByEmail } = useSubscription();
  const onSubscriptionSubmit = useCallback(async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    subscribeByEmail({ ...fieldValues, type, emailInfo, emailSubscriberPostSlug, newsletter });
  }, [subscribeByEmail, fieldValues, type, emailInfo, emailSubscriberPostSlug, newsletter]);

  const { post } = usePost(postSlug);

  useEffect(() => {
    if (!subscription || !onRedirect) {
      return;
    }
    onRedirect(postSlugSuccess ? generatePath(Pages.Success.route.path, { post: postSlugSuccess }) : '/');
  }, [subscription, postSlugSuccess, onRedirect]);

  if (subscription && !onRedirect) {
    return <Redirect to={postSlugSuccess ? generatePath(Pages.Success.route.path, { post: postSlugSuccess }) : '/'} />;
  }

  const isValid =
    regexpEmailValidator.test(fieldValues.email) &&
    (!showFullname || regexpFullnameValidator.test(fieldValues.fullname)) &&
    (!showOrganization || regexpOrganizationValidator.test(fieldValues.organization));
  const disabled = !privacyInfoRead || (newsletter && newsletterRequired && !allowReceivingNewsletter) || !isValid || loading;
  return (
    <div className="subscription">
      {post && (
        <div className="post subscription-post">
          <div className="title">{post.title}</div>
          <div className="lead">{post.lead}</div>
          <div className="ql-editor content" dangerouslySetInnerHTML={{ __html: post.content }} /> {/* eslint-disable-line react/no-danger */}
        </div>
      )}
      <form onSubmit={onSubscriptionSubmit}>
        <div className="form-content">
          {showFullname && (
            <label className="field" htmlFor="fullname">
              <div>Teljes név<span>*</span></div>
              <input
                type="text"
                id="fullname"
                name="fullname"
                disabled={loading}
                placeholder="Név..."
                value={fieldValues.fullname}
                onChange={onInputChange}
              />
            </label>
          )}
          {showOrganization && (
            <label className="field" htmlFor="organization">
              <div>Szervezet<span>*</span></div>
              <input
                type="text"
                id="organization"
                name="organization"
                disabled={loading}
                placeholder="Szervezet..."
                value={fieldValues.organization}
                onChange={onInputChange}
              />
            </label>
          )}
          {showPhone && (
            <label className="field" htmlFor="phone">
              <div>Telefonszám</div>
              <input
                type="tel"
                id="phone"
                name="phone"
                disabled={loading}
                placeholder="Telefonszám..."
                value={fieldValues.phone}
                onChange={onInputChange}
              />
            </label>
          )}
          <label className="field" htmlFor="phone">
            <div>E-mail<span>*</span></div>
            <input
              type="email"
              id="email"
              name="email"
              disabled={loading}
              placeholder="E-mail cím..."
              value={fieldValues.email}
              onChange={onInputChange}
            />
          </label>
        </div>
        {error && <div className="error">{getError(error && error.response && error.response.data)}</div>}
        {showPrivacyInfo && (
          <div className="privacy-check-item">
            <Checkbox id="privacyInfoRead" className="privacy-checkbox" checked={privacyInfoRead} onChange={onPrivacyInfoRead} size={4} />
            <label className="label" htmlFor="privacyInfoRead"> {/* eslint-disable-line */}
              Az <a href="#" onClick={onPrivacyInfoClick} target="_blank" rel="noopener noreferrer">adatkezelési tájékoztatót</a> elolvastam és megismertem
            </label>
          </div>
        )}
        {newsletter && (
          <div className="privacy-check-item">
            <Checkbox
              id="allowReceivingNewsletters"
              className="privacy-checkbox"
              checked={allowReceivingNewsletter}
              onChange={onAllowReceivingNewsletterChange}
              size={4}
            />
            <label className="label" htmlFor="allowReceivingNewsletters"> {/* eslint-disable-line */}
              Hozzájárulok, hogy az Adatkezelő hírlevelet küldjön részemre
            </label>
          </div>
        )}
        {button && <Button className="subscribe-button green text" disabled={disabled} alt="Feliratkozom" onClick={onSubscriptionSubmit}>{button}</Button>}
      </form>
    </div>
  );
};

Subscription.propTypes = {
  type: PropTypes.string.isRequired,
  postSlug: PropTypes.string,
  postSlugSuccess: PropTypes.string,
  button: PropTypes.string,
  newsletter: PropTypes.bool,
  newsletterRequired: PropTypes.bool,
  showFullname: PropTypes.bool,
  showOrganization: PropTypes.bool,
  showPhone: PropTypes.bool,
  showPrivacyInfo: PropTypes.bool,
  onRedirect: PropTypes.func,
  emailSubscriberPostSlug: PropTypes.string,
  emailInfo: PropTypes.bool,
};

Subscription.defaultProps = {
  postSlug: undefined,
  postSlugSuccess: undefined,
  button: undefined,
  newsletter: false,
  newsletterRequired: false,
  showFullname: false,
  showOrganization: false,
  showPhone: false,
  showPrivacyInfo: true,
  onRedirect: undefined,
  emailSubscriberPostSlug: undefined,
  emailInfo: false,
};

export default Subscription;
