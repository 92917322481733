import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, matchPath, useLocation } from 'react-router';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import './Menu.scss';

const Menu = React.forwardRef(({ className, items, ...props }, ref) => {
  const location = useLocation();
  return (
    <div ref={ref} className={clsx('menu', className)} {...props}>
      {items.map(({ title, route, mobileOnly }) => {
        const active = route ? matchPath(location.pathname, route) : false;
        return (
          route ? (
            <NavLink
              key={title}
              className={clsx('menu-item', { active, 'mobile-only': mobileOnly })}
              to={generatePath(route.path)}
            >
              {title}
            </NavLink>
          ) : (
            <span key={title} className={clsx('menu-item', { active, 'mobile-only': mobileOnly })}>
              {title}
            </span>
          )
        );
      })}

    </div>
  );
});

Menu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
