import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { CategoryPropType, ImagePropType, TagPropType } from 'consts';
import { calcCategoryColor, getDisplayDate, getPostUrl } from 'utils';
import { CategoryLink, PostImage, PostLink, PostVideo } from 'view/base';

import './PostTile.scss';
import './PostTile.responsive.scss';

const imageSizes = [{
  col: 1,
  crop: 'narrow',
}, {
  col: 1,
  crop: 'wide',
}];

const PostTile = ({
  className, slug, segment, title, lead, categories, image, imageStyles, redirectUrl, attributes, approvedAt, updatedAt, createdAt,
  category, defaultCategoryColor, showCategory,
}) => {
  const videoUrl = attributes?.videoUrl;
  const noImage = !image && !videoUrl;
  const displayDate = getDisplayDate(approvedAt || updatedAt || createdAt);

  const realCategory = categories[0] || category;
  const renderCategory = showCategory && realCategory && categories;
  const categoryColor = (categories && realCategory && calcCategoryColor(realCategory, categories)) || defaultCategoryColor;

  return (
    <article className={clsx('post-tile', className, { 'no-category': !category, 'no-title': title.substr(0, 3) === '###', 'no-image': noImage })}>
      <div className="post-tile-inner">
        {
          (videoUrl && <PostVideo url={videoUrl} />) ||
          (image && imageSizes.map(({ col, crop }) => (
            <PostImage key={col + crop} image={image} imageStyles={imageStyles} crop={crop} col={col} slug={slug} redirectUrl={redirectUrl} />
          )))
        }
        <div className="post-tile-content">
          {/* category && <CategoryLink className="category" category={category} showArrow /> */}
          <h1>
            <PostLink className="post-title" slug={slug} segment={segment} redirectUrl={redirectUrl} style={{ color: categoryColor }}>
              {title.split('|').map((part) => <div key={part}>{part}</div>)}
            </PostLink>
          </h1>
          {(renderCategory || !!displayDate) && (
            <h3 className="created-at-and-category">
              {displayDate && <span className="created-at">{displayDate}{renderCategory && ', '}</span>}
              {renderCategory && <CategoryLink className="category" category={{ ...realCategory, segments: segment }} showArrow />}
            </h3>
          )}
          {lead && (
            <h2 className="post-lead">
              {lead}
              <div className="read-more">
                <NavLink to={getPostUrl({ slug, segment })} style={{ color: categoryColor }}>&raquo;</NavLink>
              </div>
            </h2>
          )}
          <div className="read-more">
            <NavLink to={getPostUrl({ slug, segment })} style={{ color: categoryColor }}>Tovább olvasom &raquo;</NavLink>
          </div>
        </div>
      </div>
    </article>
  );
};

PostTile.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
  slug: PropTypes.string.isRequired,
  segment: PropTypes.string,
  title: PropTypes.string.isRequired,
  lead: PropTypes.string,
  content: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  image: ImagePropType,
  imageStyles: PropTypes.string,
  source: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  redirectUrl: PropTypes.string,
  tags: PropTypes.arrayOf(TagPropType), // eslint-disable-line react/no-unused-prop-types
  categories: PropTypes.arrayOf(CategoryPropType),
  attributes: PropTypes.shape({
    videoUrl: PropTypes.string,
  }),
  approvedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  defaultCategoryColor: PropTypes.string,
  category: CategoryPropType,
  showCategory: PropTypes.bool,
};

PostTile.defaultProps = {
  className: '',
  segment: undefined,
  lead: '',
  content: '',
  source: '',
  redirectUrl: '',
  tags: [],
  categories: [],
  image: undefined,
  imageStyles: undefined,
  attributes: {},
  approvedAt: null,
  updatedAt: null,
  createdAt: null,
  defaultCategoryColor: undefined,
  category: null,
  showCategory: false,
};

export default PostTile;
