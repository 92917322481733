import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';

import { getImageUrl } from 'utils';
import { Button } from 'view/base';

import './Gallery.scss';
import clsx from 'clsx';

const Gallery = ({ gallery, selectedItem, onClose }) => (
  <div className={clsx('gallery', { modal: onClose })}>
    <div className="gallery-head">
      <h1 className="title">GALÉRIA &rsaquo; {gallery.title}</h1>
      {gallery.description && <h2 className="lead">{gallery.description}</h2>}
      {onClose && <Button className="toggle-button material-icons" onClick={onClose} title="Bezárás">close</Button>}
    </div>
    <Carousel selectedItem={selectedItem}>
      {gallery.media.map((media) => (
        <div key={media.id} className="gallery-item">
          <img key={media.id} src={getImageUrl(media.id)} alt={media.title} />
          {media.title && <div className="gallery-item-title">{media.title}</div>}
        </div>
      ))}
    </Carousel>
  </div>
);

Gallery.propTypes = {
  gallery: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    media: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  selectedItem: PropTypes.shape(),
  onClose: PropTypes.func,
};

Gallery.defaultProps = {
  selectedItem: undefined,
  onClose: undefined,
};

export default Gallery;
