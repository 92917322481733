import { each, findIndex, isNil, shuffle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { Like } from 'react-facebook';
import ReactMarkdown from 'react-markdown';

import { SubscriptionTypeEnum, PostPropType } from 'consts';
import { useCategories, useDynamicModules, useSegment } from 'contexts';
import { calcCategoryColor, getDisplayDate, getPostCreator, getTagUrl } from 'utils';
import { PostImage } from 'view/base';
import { CategoryBreadcrumb, Post, Subscription, Survey } from 'view/components';

import Gallery from '../Gallery/Gallery';

import VoteButton from './VoteButton';
import VotePosts from './VotePosts';

import './PostContent.scss';
import './PostContent.responsive.scss';
import './PostContent.editor.scss';

const modulesMap = {
  POLL: VotePosts,
  SUBSCRIPTION: Subscription,
  SURVEY: Survey,
  MARKDOWN: ReactMarkdown,
};

const POST_SLUG_NEWSLETTER_SUBSCRIPTION = 'hirlevel-feliratkozas-info';
const POST_SLUG_NEWSLETTER_SUBSCRIPTION_SUCCESS = 'hirlevel-feliratkozas-sikeres';

const imageSizes = [{
  col: 1,
  crop: 'narrow',
}, {
  col: 1,
  crop: 'wide',
}];

const injectImageWrappers = (content, gallery) => {
  if (!gallery?.media) {
    return content;
  }

  const injectedContent = (content || '').replace(/<img(?:.+?)>/ig, (img) => {
    const [, imageId] = img.match(/\/api\/image\/(\d+)/i) || [];
    const imageIdx = findIndex(gallery.media, ({ id }) => id === +imageId);
    if (imageIdx < 0) {
      return img;
    }

    const [, style = ''] = img.match(/style="(.+?)"/) || [];
    return `<div class="gallery-image" data-image-id="${imageId}" data-image-idx="${imageIdx}" style="${style}">${img.replace(/style="(?:.+?)"/i, '')}</div>`;
  });

  return injectedContent;
};

const renderRelatedPosts = (related) => !!related.length && (
  <section className="related-posts">
    <div className="head">KAPCSOLÓDÓ</div>
    {shuffle(related).slice(0, 3).map((relatedPost, idx) => (
      <Post key={relatedPost.id} className={clsx('related-post', { even: idx % 2 })} post={relatedPost} imageSize="related" crop="narrowCrop" />
    ))}
  </section>
);

const PostContent = (({
  className, post, related, likePageUrl, code, skipBreadcrumb, skipHeader, skipGallery, allowVote, allowSubscription,
}) => {
  const { activeSegment } = useSegment();
  const { categories = [] } = useCategories();
  const [galleryItem, setGalleryItem] = useState(null);

  const {
    id: postId, title, lead, content, contentMode, approvedAt, updatedAt, createdAt,
    image, imageStyles, categories: { 0: category } = [], tags = [],
    gallery,
  } = post || {};

  const onGalleryItemClick = useCallback((event) => {
    setGalleryItem(+event.currentTarget.dataset.imageIdx);
    document.querySelector('html').classList.add('no-scroll');
  }, []);

  const onGalleryClose = useCallback((/* event */) => {
    setGalleryItem(null);
    document.querySelector('html').classList.remove('no-scroll');
  }, []);

  const contentRef = useRef();
  useEffect(() => {
    if (skipGallery || !contentRef.current || !gallery?.media) {
      return undefined;
    }
    const imageList = [];
    const imageWrappers = contentRef.current.querySelectorAll('[data-image-id]');
    each(imageWrappers, (wrapper) => {
      wrapper.addEventListener('click', onGalleryItemClick);
      imageList.push(wrapper);
    });

    return () => {
      if (imageList) {
        each(imageList, (wrapper) => wrapper.removeEventListener('click', onGalleryItemClick));
      }
    };
  }, [gallery, onGalleryItemClick, skipGallery]);

  const creator = post && getPostCreator(post);
  const displayDate = getDisplayDate(approvedAt || updatedAt || createdAt);
  const categoryColor = calcCategoryColor(category, categories);

  const history = useHistory();
  const externalProps = useMemo(() => ({
    postId,
    onRedirect: (url) => history.replace(url),
  }), [postId, history]);
  const { html: contentHtml, modules, ref } = useDynamicModules(content, { main: true, externalProps, modulesMap, contentMode });
  const hasSubscriptionModule = !!modules.find(({ moduleName }) => moduleName === 'SUBSCRIPTION');

  return (
    <div className={clsx('post-content', className)}>
      <article className="post" ref={ref}>
        {!skipHeader && (
        <header>
          {image && imageSizes.map(({ col, crop }) => <PostImage key={col + crop} image={image} imageStyles={imageStyles} crop={crop} col={col} />)}
          {!image && <div className="spacer no-image" />}
          <div className="details">
            {category && !skipBreadcrumb && <CategoryBreadcrumb categories={categories} category={category} />}
            <h1 className="title" style={{ color: categoryColor }}>
              {title.split('|').map((part) => <div key={part}>{part}</div>)}
            </h1>
            <h2 className="lead">{lead}</h2>
            <h3 className="info">
              {!!displayDate && <div className="created-at">{displayDate}</div>}
              {!!creator && <div className="created-by">ÍRTA: {creator}</div>}
            </h3>
          </div>
          <div className="spacer" />
        </header>
        )}
        <div
          ref={contentRef}
          className="ql-editor content"
          dangerouslySetInnerHTML={{ __html: injectImageWrappers(contentHtml, gallery) } /* eslint-disable-line react/no-danger */}
        />
        <div className="clear" />
        {!!tags.length && (
        <div className="tags">
          {tags.map((tag) => (
            <NavLink key={tag.id} to={getTagUrl({ slug: tag.slug, segment: activeSegment })} className="item" style={tag.styles}>
              {tag.name}
            </NavLink>
          ))}
        </div>
        )}
        {(likePageUrl || allowVote) && (
          <div className="vote-like-and-share">
            {allowVote && <VoteButton post={post} />}
            {likePageUrl && <Like href={likePageUrl} layout="button_count" colorScheme="dark" size="large" showFaces="false" share />}
          </div>
        )}
      </article>
      {related && renderRelatedPosts(related)}
      {allowSubscription && category && !hasSubscriptionModule && (
        <Subscription
          type={SubscriptionTypeEnum.NEWSLETTER}
          postSlug={POST_SLUG_NEWSLETTER_SUBSCRIPTION}
          postSlugSuccess={POST_SLUG_NEWSLETTER_SUBSCRIPTION_SUCCESS}
          button="Feliratkozom"
          newsletter
          newsletterRequired
        />
      )}
      {code && <div className="page-code" dangerouslySetInnerHTML={{ __html: code }} />} {/* eslint-disable-line react/no-danger */}
      {!skipGallery && gallery && !isNil(galleryItem) && (
        <Gallery
          gallery={{ ...gallery, title: gallery.title || title }}
          selectedItem={galleryItem}
          onClose={onGalleryClose}
        />
      )}
    </div>
  );
});

PostContent.propTypes = {
  className: PropTypes.string,
  post: PostPropType.isRequired,
  related: PropTypes.arrayOf(PropTypes.shape({})),
  likePageUrl: PropTypes.string,
  skipGallery: PropTypes.bool,
  allowVote: PropTypes.bool,
  allowSubscription: PropTypes.bool,
  code: PropTypes.string,
  skipBreadcrumb: PropTypes.bool,
  skipHeader: PropTypes.bool,
};

PostContent.defaultProps = {
  className: '',
  related: null,
  likePageUrl: null,
  skipGallery: false,
  allowVote: false,
  allowSubscription: false,
  code: undefined,
  skipBreadcrumb: false,
  skipHeader: false,
};

export default PostContent;
