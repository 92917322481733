import React, { useCallback, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Button } from 'view/base';
import { getQueryParam, getSearchUrl } from 'utils';

import './SearchForm.scss';

const onSearchSubmit = (searchQuery, history) => (ev) => {
  ev.stopPropagation();
  ev.preventDefault();
  if (!searchQuery) {
    return;
  }
  history.push(getSearchUrl({ s: searchQuery }));
};

const SearchForm = ({ location: { search }, history }) => {
  const searchQuery = getQueryParam(search, 's') || '';
  const [query, setQuery] = useState(searchQuery);
  const inputRef = useRef();
  const onSearchClick = useCallback((event) => {
    if (query) {
      onSearchSubmit(query, history)(event);
      return;
    }

    inputRef.current.focus();
  }, [history, query]);

  return (
    <form className="search-form" onSubmit={onSearchSubmit(query, history)}>
      <input type="text" name="query" value={query} onChange={(event) => setQuery(event.target.value)} ref={inputRef} />
      <Button className="search-button icon" icon="/resources/images/search.png" alt="Információ" onClick={onSearchClick} />
    </form>
  );
};

SearchForm.propTypes = {
  ...ReactRouterPropTypes,
};

SearchForm.defaultProps = {
};

export default withRouter(SearchForm);
