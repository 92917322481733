import React from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox } from 'view/base';

import './PrivacyAgreementForm.scss';

const PrivacyAgreementForm = ({ agreeLabel, disagreeLabel, buttonText, privacyAgreed, onAgreeChange, onSubmitPrivacy }) => (
  <div className="privacy-agreement-form">
    <div className="checkbox-wrapper">
      <Checkbox id="privacyAgreed" value labelAlign="left" onChange={onAgreeChange} checked={privacyAgreed === true}>{agreeLabel}</Checkbox>
      <Checkbox id="privacyDisagreed" value={false} labelAlign="left" onChange={onAgreeChange} checked={privacyAgreed === false}>{disagreeLabel}</Checkbox>
    </div>
    <Button className="green text" onClick={onSubmitPrivacy} disabled={privacyAgreed !== true}>{buttonText}</Button>
  </div>
);

PrivacyAgreementForm.propTypes = {
  agreeLabel: PropTypes.string,
  disagreeLabel: PropTypes.string,
  buttonText: PropTypes.string,
  privacyAgreed: PropTypes.bool,
  onAgreeChange: PropTypes.func,
  onSubmitPrivacy: PropTypes.func,
};

PrivacyAgreementForm.defaultProps = {
  agreeLabel: 'Az adatvédelmi irányelveket elfogadom',
  disagreeLabel: 'Az adatvédelmi irányelveket nem fogadom el',
  buttonText: 'Kérdőív indítása',
  privacyAgreed: undefined,
  onAgreeChange: undefined,
  onSubmitPrivacy: undefined,
};

export default PrivacyAgreementForm;
