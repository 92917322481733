import React from 'react';
import PropTypes from 'prop-types';

import { BannerPosition } from 'consts';
import { Link } from 'view/base';
import { getImageUrl } from 'utils';

import './Banners.scss';
import { useBannersDisplay } from 'contexts';

const Banners = ({ positionId }) => {
  const { banners } = useBannersDisplay({ positionId });
  if (!banners?.length) {
    return '';
  }

  return (
    <div className="banners">
      {banners.map(({ id, target, title, priority, imageMediaId }) => (
        <Link key={id} to={target} title={title} style={{ order: priority }}>
          <img src={getImageUrl(imageMediaId, null, 'banner')} alt={title} />
        </Link>
      ))}
    </div>
  );
};

Banners.propTypes = {
  positionId: PropTypes.oneOf(Object.keys(BannerPosition, (position) => BannerPosition[position])).isRequired,
};

Banners.defaultProps = {};

export default Banners;
