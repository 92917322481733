import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';

import { usePostsByTagSlug, useSegment, useTag } from 'contexts';
import { PostRedirect } from 'view/base';
import { Pagination, PostTile } from 'view/components';
import { getTagUrl } from 'utils';

import './TagPage.scss';

const TagPage = ({ className, match }) => {
  const { activeSegment } = useSegment();
  const { params: { tag: tagSlug, page } } = match;
  const { tag } = useTag(tagSlug);
  const { posts, pages } = usePostsByTagSlug(tagSlug, page);

  if (tag === null) {
    return <Redirect to="/" />;
  }

  if (posts && pages === 1 && posts.length === 1) {
    return <PostRedirect post={posts[0]} />;
  }

  return (
    <div className={clsx(className, 'tag-page')}>
      {tag && posts && (
        <React.Fragment>
          <div className="posts">
            <div className="head">Címke: {tag.name}</div>
            {posts.map((post, idx) => (
              <PostTile className={clsx({ even: idx % 2 })} key={post.id} showCategory {...post} />
            ))}
          </div>
          {!!pages && <Pagination basePath={getTagUrl({ slug: tagSlug, segment: activeSegment, page: ':page?' })} pages={pages} page={page} />}
        </React.Fragment>
      )}
    </div>
  );
};

TagPage.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};

TagPage.defaultProps = {
  className: '',
};

export default TagPage;
