import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ChildrenPropType } from 'consts';
import './Checkbox.scss';

const SimpleCheckbox = typeof window !== 'undefined' ? require('react-simple-checkbox').default : () => '';

/*
const parseHtml = (html) => {
  const [$, tag, attrs, rest] html.match(/<([^/\s]+?)(\s+.*?|)>(.*)/is)
}
*/
const Checkbox = ({ className, id, labelAlign, value, onChange, checked, size, children }) => {
  const onCheckboxChange = useCallback((newChecked) => onChange && onChange({ id, value, checked: newChecked }), [onChange, id, value]);
  return (
    <div className={clsx(`checkbox label-${labelAlign}`, className)}>
      <SimpleCheckbox className="checkbox-input" id={id} value={value} checked={checked} onChange={onCheckboxChange} size={size} />
    {children &&<label className="checkbox-label" htmlFor={id}>{children}</label>} {/* eslint-disable-line */}
    </div>

  );
};
Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelAlign: PropTypes.oneOf(['left', 'right']),
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  checked: PropTypes.bool,
  size: PropTypes.number,
  onChange: PropTypes.func,
  children: ChildrenPropType,
};

Checkbox.defaultProps = {
  className: '',
  labelAlign: 'right',
  value: undefined,
  checked: false,
  size: 4,
  onChange: undefined,
  children: undefined,
};

export default Checkbox;
