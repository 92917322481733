import React from 'react';
import { NavLink } from 'react-router-dom';

import { Pages } from 'consts';
import { Menu } from 'view/components';

import './Footer.scss';
import './Footer.responsive.scss';

// const { REACT_APP_VERSION, REACT_APP_RELEASE_DATE } = process.env;

const topPages = [Pages.Contact];

const bottomPages = [Pages.AboutUs, Pages.Impressum, Pages.TermsOfUse, Pages.PrivacyInfo];

const Footer = (/* props */) => (

  <footer className="footer">
    <Menu className="footer-top" items={topPages} />
    <div className="footer-middle">
      <NavLink className="link" to="/">
        <img src="/resources/images/logo-mono-58.png" alt="logo" />
      </NavLink>
      <a href="http://www.europasziveben.hu" className="link" target="_blank" rel="noopener noreferrer">
        <img src="/resources/images/logo-eu-sziveben-mono.png" alt="logo" />
      </a>
    </div>
    <Menu className="footer-bottom" items={bottomPages} />
  </footer>
);

export default Footer;
