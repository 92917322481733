const services = {
  getBanners: {
    url: '/portal/banners',
  },
  getCategories: {
    url: '/portal/category',
    params: {
      segment: 'query',
    },
  },
  getCategory: {
    url: '/portal/category/:categoryId',
    params: {
      categoryId: 'url',
      segment: 'query',
    },
  },
  getPosts: {
    url: '/portal/post',
    params: {
      segment: 'query',
    },
  },
  getPostsByCategorySlug: {
    url: '/portal/post/category/slug/:slug',
    params: {
      slug: 'url',
      page: 'query',
      segment: 'query',
    },
  },
  getPostsByTagSlug: {
    url: '/portal/post/tag/slug/:slug',
    params: {
      slug: 'url',
      page: 'query',
      segment: 'query',
    },
  },
  getPostsBySearch: {
    url: '/portal/post/search',
    params: {
      query: 'query',
      page: 'query',
      segment: 'query',
    },
  },
  getPostBySlug: {
    url: '/portal/post/slug/:slug',
    params: {
      slug: 'url',
      page: 'query',
      preview: 'query',
    },
  },
  getTagBySlug: {
    url: '/portal/tag/slug/:slug',
    params: {
      slug: 'url',
      page: 'query',
      segment: 'query',
    },
  },
  getTagCloud: {
    url: '/portal/tag-cloud',
    params: {
      segment: 'query',
    },
  },
  postSubscription: {
    method: 'post',
    url: '/subscription',
    params: {
      type: 'body',
      email: 'body',
      fullname: 'body',
      organization: 'body',
      phone: 'body',
      emailInfo: 'body',
      emailSubscriberPostSlug: 'body',
      status: 'body',
      newsletter: 'body',
    },
    headers: {
      'content-type': 'application/json',
    },
    noCache: true,
  },
  postSurvey: {
    method: 'post',
    url: '/portal/survey/:postId',
    params: {
      postId: 'url',
      responses: 'body',
      privacyAgreed: 'body',
    },
    headers: {
      'content-type': 'application/json',
    },
    noCache: true,
  },
  postVote: {
    method: 'post',
    url: '/portal/vote',
    params: {
      postId: 'body',
      tokenDetail: 'body',
      profile: 'body',
    },
    headers: {
      'content-type': 'application/json',
    },
    noCache: true,
  },
  getMediaBySlug: {
    method: 'get',
    url: '/portal/media/slug/:slug',
    params: {
      slug: 'url',
    },
  },
  getPostsByPollId: {
    url: '/portal/post/poll/:pollId',
    params: {
      pollId: 'url',
    },
  },
  getGalleries: {
    url: '/portal/gallery',
  },
};

export default services;
