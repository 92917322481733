import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { CategoryStatus } from 'consts';
import { useCategories, useSegment } from 'contexts';
import { getCategoryUrl } from 'utils';

import './CategoryFooter.scss';

const CategoryFooter = ({ className }) => {
  const { activeSegment } = useSegment();
  const { categories = [] } = useCategories();
  const rootCategories = categories.filter(({ parentId, status }) => !parentId && status !== CategoryStatus.HIDDEN);

  return (
    <div className={clsx('category-footer', className)}>
      {rootCategories.map(({ id, name, slug }) => {
        const subCategories = categories.filter(({ parentId, status }) => parentId === id && status !== CategoryStatus.HIDDEN);

        return (
          <div key={id} className="category">
            <NavLink to={getCategoryUrl({ slug, segment: activeSegment })} className="category-name">{name}</NavLink>
            {!!subCategories.length && (
              <div className="subcategories">
                {subCategories.map(({ id: subId, name: subName, slug: subSlug }) => (
                  <NavLink key={subId} className="subcategory" to={getCategoryUrl({ slug: subSlug, segment: activeSegment })}>{subName}</NavLink>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

CategoryFooter.propTypes = {
  className: PropTypes.string,
};

CategoryFooter.defaultProps = {
  className: '',
};

export default CategoryFooter;
