import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { HOST_URL, Segment } from 'consts';
import { usePost, useSegment } from 'contexts';
import { getCroppingImageStyles, getImageUrl } from 'utils';
import { PostRedirect } from 'view/base';
import { MainView, PostContent } from 'view/modules';

import './PostPage.scss';
import './PostPage.responsive.scss';

const allowLike = false;
const allowVote = false;
const allowSubscription = false;
const allowRelated = false;

const PostPage = ({ className, location, match, code, skipBreadcrumb, skipHeader, skipBackground }) => {
  const { activeSegment } = useSegment();
  const { pathname } = location;
  const { params: { post: slug } } = match;
  const { post, related = [], error, clearCache } = usePost(slug);

  const postId = post && post.id;
  // clear item from cache when component is unmounted
  useEffect(() => () => {
    if (postId) {
      clearCache();
    }
  }, [postId, clearCache]); // eslint-disable-line react-hooks/exhaustive-deps

  const { title, lead, attributes, redirectUrl, segment, image, imageStyles } = post || {};

  // check if post is found or not...
  if (post === null || error) {
    // ... and return 404 or redirec to root page
    return <Redirect to="/" />;
  }

  if (!post) {
    return (
      <MainView className={clsx({ 'bg-post': !skipBackground })} loaded={false}>
        <div className={clsx(className, 'post-page')} />
      </MainView>
    );
  }

  if (post && ((segment !== Segment.$GENERIC && activeSegment !== segment) || redirectUrl)) {
    return <PostRedirect post={post} />;
  }

  const pageUrl = `${HOST_URL}${pathname}`;

  return (
    <MainView className={clsx({ 'bg-post': !skipBackground })} loaded>
      <div className={clsx(className, 'post-page')}>
        {post && (
          <div className="page-inner">
            <Helmet>
              <title>{title} - Szent István Intézet</title>
              <meta name="description" content={attributes?.description || lead} />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="hu_HU" />
              <meta property="og:site_name" content="Szent István Intézet" />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={attributes?.description || lead} />
              <meta property="og:url" content={pageUrl} />
              {!!image && (
                <meta
                  property="og:image"
                  content={`${HOST_URL}${getImageUrl(image, getCroppingImageStyles(imageStyles, 'narrowCrop'), 'facebook')}`}
                />
              )}
              {!!image && HOST_URL.startsWith('https://') && (
                <meta
                  property="og:image:secure_url"
                  content={`${HOST_URL}${getImageUrl(image, getCroppingImageStyles(imageStyles, 'narrowCrop'), 'facebook')}`}
                />
              )}
              {!!(image && image.contentType) && <meta property="og:image:type" content={image.contentType} />}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={attributes?.description || lead} />
              <meta name="twitter:image" content={`${HOST_URL}${getImageUrl(image, getCroppingImageStyles(imageStyles, 'wideCrop'), 'twitter')}`} />
            </Helmet>
            <PostContent
              post={post}
              related={allowRelated ? related : null}
              likePageUrl={allowLike ? pageUrl : null}
              code={code}
              skipBreadcrumb={skipBreadcrumb}
              skipHeader={skipHeader}
              allowVote={allowVote}
              allowSubscription={allowSubscription}
            />
          </div>
        )}
      </div>
    </MainView>
  );
};

PostPage.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  className: PropTypes.string,
  code: PropTypes.string,
  skipBreadcrumb: PropTypes.bool,
  skipHeader: PropTypes.bool,
  skipBackground: PropTypes.bool,
};

PostPage.defaultProps = {
  className: '',
  code: undefined,
  skipBreadcrumb: false,
  skipHeader: false,
  skipBackground: false,
};

export default PostPage;
