import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Pages } from 'consts';
import { Menu, SearchForm } from 'view/components';

import MenuIcon from './MenuIcon';

import './Header.scss';
import './Header.responsive.scss';

const mainPages = [
  Pages.AboutUs, Pages.Programs, Pages.Research, Pages.Studies, Pages.Press,
];

const subPages = [...mainPages, Pages.Video, Pages.Gallery, Pages.Partners];

const SocialMedia = () => (
  <div className="social-media">
    <a href="https://www.youtube.com/channel/UCtqtNtdkInki7HGj7ocfxgA" target="_blank" rel="noopener noreferrer">
      <img alt="YouTube" src="/resources/images/youtube-logo.png" />
    </a>
    <a href="https://www.facebook.com/szentistvanintezet/" target="_blank" rel="noopener noreferrer">
      <img alt="YouTube" src="/resources/images/facebook-logo.png" />
    </a>
  </div>
);

const Header = () => {
  const mobileMenuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);
  const onMenuClick = () => setMenuOpen((prevOpen) => !prevOpen);

  return (
    <React.Fragment>
      <div className="header">
        <div className="logo">
          <NavLink to="/">
            <img src="/resources/images/logo.png" alt="Szent István Intézet logo" />
          </NavLink>
        </div>
        <div className="ezer-evert-logo">
          <img src="/resources/images/ezer-evert-logo.png" alt="A következő ezer évért logo" />
        </div>
        {Pages && (
        <div className="menu-container">
          <SocialMedia />
          <div className="menu-wrapper">
            {subPages && (
            <button className={clsx('menu-button', { opened: menuOpen })} type="button" onClick={onMenuClick}>
              <MenuIcon />
              {menuOpen && (
                <Menu className="submenu desktop" items={subPages} />
              )}
            </button>
            )}
            <Menu className="header-menu" items={mainPages} />
            <SocialMedia />
            <SearchForm />
          </div>
        </div>
        )}
      </div>
      <Menu ref={mobileMenuRef} className="submenu mobile" items={subPages} style={{ height: (menuOpen && mobileMenuRef.current?.scrollHeight) || 0 }} />
    </React.Fragment>
  );
};

export default Header;
