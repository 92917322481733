import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Login } from 'react-facebook';

import { usePostsByPollId } from 'contexts';
import { Button, PostLink } from 'view/base';

import usePoll from './usePoll';

import './VotePosts.scss';

const VotePosts = ({ className, pollId }) => {
  const [disabled, setDisabled] = useState(false);
  const [filter, setFilter] = useState('');
  const { posts, refetch } = usePostsByPollId(pollId);
  const post = posts && posts[0];
  const { available, onLoginComplete, onLoginError, reset, message } = usePoll(post && post.poll, refetch);

  useEffect(() => {
    setDisabled(!!message);
  }, [message]);

  const onFilterChange = useCallback((event) => {
    setFilter(event.currentTarget.value);
  }, []);

  const filteredPosts = useMemo(() => {
    if (!posts || !filter) {
      return posts;
    }

    const normalizedFilter = filter.normalize ? filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : filter;

    return posts.filter(({ title }) => {
      const normalizedTitle = title.normalize ? title.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : title;
      return normalizedTitle.trim().toLowerCase().indexOf(normalizedFilter.trim().toLowerCase()) >= 0;
    });
  }, [posts, filter]);

  if (!available) {
    return '';
  }

  return (
    <div className={clsx('vote-posts', className)}>
      <div className="filter">
        <input value={filter} onChange={onFilterChange} placeholder="Szűrés a történet címére..." />
      </div>
      {filteredPosts.map(({ id, title, lead, slug, redirectUrl, segment, voteCount }) => (
        <div key={id} className="vote-post-item">
          <div className="vote-post">
            <PostLink className="vote-post-title" slug={slug} redirectUrl={redirectUrl} segment={segment}>{title}</PostLink>
            <div className="vote-post-lead">{lead}</div>
          </div>
          <Login eventKey={id} scope="email" onCompleted={onLoginComplete} onError={onLoginError}>
            {({ handleClick/* , loading , error */ }) => (
              <div className="vote-info">
                <div className="vote-count">{voteCount || 'Még nincs'} szavazat</div>
                <Button
                  className="vote-post-button green text"
                  disabled={disabled}
                  onClick={(...args) => {
                    setDisabled(true);
                    return handleClick(...args);
                  }}
                >Erre szavazok
                </Button>
              </div>
            )}
          </Login>
        </div>
      ))}
      {!filteredPosts.length && <div className="empty-result">A szavazásban ilyen szűrési feltétellel nincsenek történetek!</div>}
      {message && (
        <div className="vote-message">
          <div className="vote-message-dialog">
            <div className="msg-icon material-icons">{message.icon}</div>
            <div className="msg-title">{message.title}</div>
            <div className="msg-content">{message.content}</div>
            {message.button && <Button className="msg-button green text" onClick={reset}>{message.button}</Button>}
          </div>
        </div>
      )}
    </div>
  );
};

VotePosts.propTypes = {
  className: PropTypes.string,
  pollId: PropTypes.number.isRequired,
};

VotePosts.defaultProps = {
  className: '',
};

export default VotePosts;
