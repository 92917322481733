/* eslint-disable import/prefer-default-export */

import { attempt, find, isArray, isError, reduce, set } from 'lodash';
import { DateTime } from 'luxon';

import { BASE_URL, CategoryStatus, ImageWidth } from 'consts';
import { decodeHtml, fromJson, processSurvey } from '../../../shared/utils';

export * from './path';

export { default as provider } from './provider';

export { decodeHtml, fromJson, processSurvey };

export const getImageUrl = (image, crop, size) => {
  if (!image) {
    return '/resources/images/no-image.png';
  }
  const queryWidth = ImageWidth[size] && `w=${ImageWidth[size]}`;

  const url = `${BASE_URL}/image/${Number.isInteger(image) ? image : image.id}`;
  if (!crop) {
    return `${url}${queryWidth ? `?${queryWidth}` : ''}`;
  }

  const { x, y, width, height } = crop;
  return `${url}?cx=${x}&cy=${y}&cw=${width}&ch=${height}${queryWidth ? `&${queryWidth}` : ''}`;
};

export const getCroppingImageStyles = (imageStyles, cropperId) => {
  const croppers = attempt(JSON.parse, imageStyles);
  if (!croppers || isError(croppers)) {
    return null;
  }

  const cropper = croppers[cropperId];
  return (cropper && cropper.pixels) || null;
};

export const findCategoryById = (categories, id) => find(categories, (category) => category.id === id);

export const findCategoryBySlug = (categories, slug) => find(categories, (category) => category.slug === slug);

export const calcCategoryColor = ({ id } = {}, categories = []) => {
  const category = findCategoryById(categories, id) || {};
  const { parentId, color } = category;
  if (color) {
    return color;
  }

  const parentCategory = parentId && findCategoryById(categories, parentId);
  const basicColor = parentCategory ? calcCategoryColor(parentCategory, categories) : color;
  return !basicColor || basicColor === '#000' || basicColor === '#000000' || basicColor.startsWith('rgba(0,0,0,') ? undefined : basicColor;
};

export const getDisplayName = (user, showUsername = true, empty = '') => {
  if (!user) {
    return empty;
  }

  const { profile, profile: { displayName, firstName, lastName } = {} } = user;
  const username = showUsername ? user.username : '';
  if (!profile) {
    return username;
  }

  return displayName || (`${lastName || ''} ${firstName || ''}`.trim()) || username;
};

export const getPostCreator = ({ source, createdByUser }) => source || getDisplayName(createdByUser);

export const getDisplayDate = (date) => {
  const luxonDate = DateTime.fromISO(date);
  return date && luxonDate && luxonDate.toLocaleString(DateTime.DATE_SHORT);
};

export const forkPromise = (forkFn) => {
  const loadPromises = {};
  const startPromise = (promiseFn, url) => {
    loadPromises[url] = loadPromises[url] || [];

    const promise = new Promise((resolve, reject) => loadPromises[url].push({ resolve, reject }));
    if (loadPromises[url].length === 1) {
      promiseFn()
        .then((response) => {
          while (loadPromises[url].length) { loadPromises[url].shift().resolve(response); }
          delete loadPromises[url];
        })
        .catch((error) => {
          while (loadPromises[url].length) { loadPromises[url].shift().reject(error); }
          delete loadPromises[url];
        });
    }

    return promise;
  };

  return forkFn(startPromise);
};

export const getQueryParam = (search, name) => {
  if (isArray(name)) {
    return name.map((item) => getQueryParam(search, item));
  }

  const normalizedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${normalizedName}=([^&#]*)`);
  const results = regex.exec(search);
  return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getLocation = (href) => {
  const match = href.match(/^(?:(https?:)\/\/)?(([^:/?#]*){0,1}(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
  return match && {
    href,
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7],
  };
};

export const createRootCategoryMenuAdapter = (rootCategorySlug) => (categories/* , opened */) => {
  const rootCategory = categories && find(categories, ({ slug }) => slug === rootCategorySlug);
  const filteredCategories = (
    rootCategory && (categories || [])
      .filter(({ parentId }) => parentId === rootCategory.id)
      .map((category) => ({ ...category, parentId: null, status: CategoryStatus.OPENED }))
  ) || categories;

  return {
    categories: filteredCategories,
    opened: reduce(filteredCategories, (result, { id }) => set(result, id, true), {}),
    allowToggle: false,
  };
};
