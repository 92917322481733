import React from 'react';
import PropTypes from 'prop-types';

import './PostVideo.scss';

const getVideoSrc = (url) => {
  if (url.indexOf('https://www.facebook.com/') >= 0) {
    return `https://www.facebook.com/plugins/video.php?width=1192&height=564&show_text=false&href=${encodeURIComponent(url)}`;
  }

  const videoIdMatch = url.match(/v=([a-z0-9_-]{8,})/i) || url.match(/([a-z0-9_-]{8,})(?:\/|\?|$)/i);
  const videoId = videoIdMatch && videoIdMatch[1];
  if (videoId) {
    return `https://www.youtube-nocookie.com/embed/${videoId}?controls=0`;
  }

  return null;
};

const PostVideo = ({ url, ...props }) => {
  const src = getVideoSrc(url);
  if (!src) {
    return '';
  }

  return (
    <iframe
      className="post-video post-image"
      title="video"
      src={src}
      frameBorder="0"
      scrolling="no"
      allowFullScreen
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      {...props}
    />
  );
};

PostVideo.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PostVideo;
